import APIClient from '../api/apiClient'
const AuthService = {
	// Service to create a new user
	createUser: (payload) => {
		return APIClient.post(`/auth/register`, payload)
	},

	// Service to login a user
	loginUser: (payload) => {
		return APIClient.post(`/auth/login`, payload)
	},

	// Service to request password reset
	forgotPassword: (payload) => {
		return APIClient.post(`/auth/forgot-password`, payload)
	},

	// Service to request password reset with phone
	forgotPasswordPhone: (payload) => {
		return APIClient.post(`/auth/forgot-password-with-phone`, payload)
	},

	// Service to reset password
	resetPassword: (payload) => {
		return APIClient.post(`/auth/reset-password`, payload)
	},

	// Service to request email verification
	requestEmailVerification: (payload) => {
		return APIClient.post(`/auth/request-email-verification`, { ...payload, password: payload.password || undefined })
	},

	// Service to request phone verification
	requestPhoneVerification: (payload) => {
		return APIClient.post(`/auth/request-phone-verification`, payload)
	},

	// Service to verify email with pin
	verifyEmailPin: (payload) => {
		return APIClient.post(`/auth/verify-email`, payload)
	},

	// Service to verify phone number with pin
	verifyPhonePin: (payload) => {
		return APIClient.post(`/auth/verify-phone-number`, payload)
	},

	// Service to verify reset pin
	verifyResetPin: (payload) => {
		payload.userId = Number(payload.userId)
		payload.pin = Number(payload.pin)
		return APIClient.post(`/auth/verify-reset-pin`, payload)
	},

	// Service to update user email
	updateUserEmail: (payload) => {
		return APIClient.post(`/auth/update-user-email`, payload)
	},

	// Service to change user password
	changeUserPassword: (payload) => {
		return APIClient.post(`/auth/change-password`, payload)
	},

	// Service to update user bank account
	updateUserBankAccount: (payload) => {
		return APIClient.post(`/auth/update-user-bank-account`, payload)
	},

	// Service to get current logged-in user
	getCurrentUser: () => {
		return APIClient.get(`/auth/current-user`)
	}
}
export default AuthService