// import '@babel/polyfill'
import 'whatwg-fetch'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import Cleave from 'vue-cleave-component'
import i18n from './i18nVeeValidate'
import './registerServiceWorker'
import VueFlashMessage from 'vue-flash-message'
import VueTelInput from 'vue-tel-input'
import { logger } from '@/logger'
import { config, environment } from '@/config'
import VueClipboard from 'vue-clipboard2'
import VueGtm from 'vue-gtm'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import APIClient from './api/apiClient'

Vue.config.productionTip = false
Vue.config.devtools = true;
Vue.use(VueFlashMessage, {
	messageOptions: {
		timeout: 1, // Timeout time set in animation
		important: true,
		pauseOnInteract: false,
	},
})

Vue.use(VueTelInput, {
	mode: 'international',
})

Vue.use(Cleave)

Vue.use(VueClipboard)

Vue.use(VueGtm, {
	id: config.googleTagManagerId, // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
	defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
	enabled: Boolean(config.gtmEnabled), // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
	// debug: true, // Whether or not display console logs debugs (optional)
	// loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
	vueRouter: router, // Pass the router instance to automatically sync with router (optional)
	// ignoredViews: ['homepage'], // Don't trigger events for specified router names (case insensitive) (optional)
	trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
})

Vue.component('VSelect', vSelect)


window.addEventListener('beforeinstallprompt', (e) => {
  e.preventDefault(); // Prevent the automatic prompt
});

new Vue({
	router,
	store,
	i18n,
	render: (h) => h(App),
}).$mount('#app')

Vue.config.errorHandler = function (err, vm, info) {
	logger.debug(`[Global Error Handler]: Error in ${info}: ${err}`)

	if (environment !== 'development') {
		if(vm.$children[0].$vnode.componentOptions.tag !=='MollieStep2'){
			store.dispatch('logError', `Error in ${info} - ${err}`)
		}
		
	} else {
		logger.debug(err.message)
		logger.debug(err.stack)
	}
}

Vue.prototype.$googleMapsApiKey = config.googleApiKey

window.onerror = (message, source, fileno, column, err) => {
	logger.debug('Unhandled error', message, source, fileno, column, err)

	if (environment !== 'development') {
		store.dispatch('logError', `(unhandled) ${message} in ${source} at ${fileno} - ${column}`)
	}
}
