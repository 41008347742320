import APIClient from '../api/apiClient'

const SessionService = {
	/**
	 * @description will get all session of all users, will be used by admin only
	 */
	getAllSessions: (params) => {
		return APIClient.get(`/user-sessions/all-sessions`, { params })
	},

	/**
	 * @description will create new session for a user with given sessionData
	 */
	createNewSession: (sessionData) => {
		return APIClient.post(`/user-sessions/session-operations`, sessionData)
	},

	/**
	 * @description will start session specified by sessionId
	 */
	startSession: (sessionId) => {
		return APIClient.put(`/user-sessions/session-operations/${sessionId}/start`, {})
	},

	/**
	 * @description will update user session by given data
	 */
	updateSession: (sessionId, sessionData) => {
		return APIClient.put(`/user-sessions/session-operations/${sessionId}/update`, sessionData)
	},

	/**
	 * @description will get user session detail by sessionId
	 */
	getSessionById: (sessionId) => {
		return APIClient.get(`/user-sessions/session-operations/${sessionId}`)
	},

	/**
	 * @description  will generate qrcode of specific type for an active session 
	 */
	generateSessionQRCode: (sessionId, payload) => {
		return APIClient.post(`/user-sessions/session-operations/${sessionId}/generate-qr`, payload)
	},

	/**
	 * @description will get all sessions of a user by user id
	 */
	getSessionsByUserId: (userid) => {
		return APIClient.get(`/user/user-operations/${userid}/user-sessions`).then((response)=>{
			return response.data.userSessions || []
		})
	},

	/**
	 * @description will get user live session if any
	 */
	getLiveSessionByUserId: (userid) => {
		return APIClient.get(`/user/user-operations/${userid}/user-live-session`).then((response)=>{
			return response?.data?.userLiveSession || null
		})
	},

}

export default SessionService
