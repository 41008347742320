import APIClient from '../api/apiClient'

const GeneralService = {
	/**
	 * @description will get current server time
	*/
	getServerTime: () => {
		return APIClient.get(`/server-time`).then(response => response?.data)
	},

}

export default GeneralService
