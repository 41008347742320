import graphqlClient from '@/api/db'
import gql from 'graphql-tag'
import { isNil, omit, pick, find, findIndex, reject, map, filter, includes } from 'lodash/fp'
import { logger } from '@/logger'
import router from '@/router'
import Vue from 'vue'
import {
	config,
	addressTypes,
	SUBSCRIPTION_STATES,
	REQUEST_STATES,
	PROPOSAL_STATES,
	MAX_POI,
} from '@/config'
import i18n from '@/i18nVeeValidate'
import myParkings from './myParkings'
import AuthService from '../../services/auth'

// Initial state
const initialState = () => ({
	currentUser: {
		addresses: [],
		subscriptions: [],
		requests: [],
		proposals: [],
		children: [],
	},
	userInAdmin: {
		addresses: [],
		subscriptions: [],
		requests: [],
		proposals: [],
		children: [],
	},
	isAdminEdit: false,
	activeTemporaryAccess: {},
	parentUser: {},
	activeAddressId: 0,

	// familyAccounts: [
	// 	{ id: 36, firstName: 'Robin', lastName: 'Deneyer' },
	// 	{ id: 38, firstName: 'Stijn', lastName: 'Pint' },
	// ],
	isChild: false,
	valid: true,
	loading: false,
	formErrors: {},
})

const state = initialState()
const commonUserProps = `
		subscriptions {
			id
			status
			paymentStatus
			isPaymentLink
			start
			end
			isCargo
			parking {
				legacyAddress {
				  en
				  fr
				  nl
				}
			name

			}
			subscriptionType {
				name
				price
				duration
			}
			parkingCode
			spotNumber
			invoiceItems {
				id
				status
				amount
				description
				type
				paid
				paidAt
				createdAt
				mollieId
				stripeId
			}
			cancelRequestedAt
			deposits {
				id
				price
				status
				reimbursedAt
				incomedAt
				legacy
				paymentMethod
				stripeId
				mollieId
			}
		}
		requests {
			id
			type
			comment
			requestedAt
			createdAt
			status
			addressId
			isCargo
			address {
				addressType
				streetName
				houseNumber
				id
			}
			parkingsInRange {
				code
			}
		}
		proposals {
			id
			requestId
			status
			urlCode
			validUntil
			parking {
				id
				code
				name
				parkingTypeName
				mobib
				airKey
				bePark
				badgeAccess
				legacyAddress {
					nl
					fr
					en
				}
			}
		}
		addresses {
			id
			addressType
			streetName
			houseNumber
			busNumber
			city
			postalCode
			longitude
			latitude
			realAddress
		}
`
const childUserProps = `
	id
	firstName
	lastName
	mobib
	status
	parentId
	parent {
		id
		firstName
		lastName
		email
		phoneNumber
		phoneNumberTemp
		phoneVerified
		bankAccount
	}
	activeProposals {
		id
	}
${commonUserProps}
`

export const userProps = `
		id
		firstName
		lastName
		email
		phoneNumber
		phoneNumberTemp
		phoneVerified
		language
		newsletter
		mobib
		status
		existsInStripe
		existsInMollie
		hasVerificationId
		paymentMethod
		bankAccount
		company {
			name
			vatNumber
			address {
				id
				streetName
				houseNumber
				busNumber
				city
				postalCode
			}
		}
		activeTemporaryAccess {
			id
			price
			startCodeGeneratedAt
			endCodeGeneratedAt
			status
			startedAt
			endedAt
			parking {
			    id
			    code
			}
		}
${commonUserProps}
		children {
			${childUserProps}
		}
		admin
	`

const AddressProps = [
	'id',
	'addressType',
	'city',
	'postalCode',
	'streetName',
	'houseNumber',
	'busNumber',
	'titularLine',
	'latitude',
	'longitude',
	'realAddress',
]
const CompanyProps = ['name', 'vatNumber', 'address']
const UserUpdateProps = ['firstName', 'lastName', 'language', 'newsletter', 'mobib']

const updateUser = (_, props, childId = undefined, updateUserId = undefined) => {
	return graphqlClient.mutate({
		mutation: gql`
			mutation UpdateUser($user: UserUpdateProps!, $childId: Int, $updateUserId: Int) {
				updateUser(props: $user, childId: $childId, updateUserId: $updateUserId) {
					user {
						id
						company {
							address {
								id
							}
						}
					}
					errors {
						vatNumber
					}
				}
			}
		`,
		variables: {
			user: props,
			childId,
			updateUserId,
		},
	})
}

const clearChildToken = () => {
	localStorage.removeItem('cycloChild')
}

export const getChildToken = () => {
	return localStorage.getItem('cycloChild')
		? parseInt(localStorage.getItem('cycloChild'), 10)
		: undefined
}

export const setChildToken = (id) => localStorage.setItem('cycloChild', id)

// Getters
const getters = {
	isLoaded: (state) => {
		return !isNil(state.currentUser.email) || !isNil(state.currentUser.parentId)
	},
	getCurrentUserCopy: (state) => {
		return Object.assign({}, state.currentUser)
	},
	getFormErrors: (state) => {
		return Object.assign({}, state.formErrors)
	},
	hasBigParkingSubscription: (state) =>
		Boolean(
			find(
				(s) => s.subscriptionType.name === 'BigParking' && [2, 3, 4].includes(s.status),
				state.currentUser.subscriptions
			) ||
				find(
					(s) =>
						s.subscriptionType.name !== 'BigParking' &&
						[2, 3, 4].includes(s.status) &&
						s.end >= new Date().setHours(0, 0, 0, 0),
					state.currentUser.subscriptions
				)
		),

	subsToShow: (state) => {
		return reject(
			(sub) => parseInt(sub.status, 10) === SUBSCRIPTION_STATES.new,
			state.currentUser.subscriptions
		)
	},

	// only one home address allowed
	availableAddressTypes: (state, gtrs) => {
		const inUse = map(
			(a) => a.addressType,
			state.isAdminEdit ? state.userInAdmin.addresses : state.currentUser.addresses
		)
		const homeInUse = includes('home', inUse)
		const mulitplePoi = inUse.filter((item) => item === 'poi').length >= MAX_POI
		const langAddressTypes = gtrs.addressTypes

		if (homeInUse) {
			const addresses = reject((type) => type.key === 'home', langAddressTypes)

			return mulitplePoi ? reject((type) => type.key === 'poi', addresses) : addresses
		} else {
			const parentId = state.isAdminEdit ? state.userInAdmin.parentId : state.currentUser.parentId

			return parentId ? langAddressTypes : filter({ key: 'home' }, langAddressTypes)
		}
	},

	// a the moment we have either home or POI as type
	availableAddressType: (state, gtrs) => {
		const inUse = map(
			(a) => a.addressType,
			state.isAdminEdit ? state.userInAdmin.addresses : state.currentUser.addresses
		)
		const homeInUse = includes('home', inUse)
		const langAddressTypes = gtrs.addressTypes

		if (homeInUse) {
			return reject((type) => type.key === 'home', langAddressTypes)[0]
		} else {
			return reject((type) => type.key === 'poi', langAddressTypes)[0]
		}
	},

	addressTypes: () => {
		return addressTypes.map((type) => ({
			key: type,
			value: i18n.t(`profile.address.addressTypes.${type}`),
		}))
	},

	activeProposals: (state) => filter({ status: 1 }, state.currentUser.proposals),
	activeTemporaryAccess: (state) => {
		return state.activeTemporaryAccess.id
			? state.activeTemporaryAccess
			: state.currentUser.activeTemporaryAccess
	},

	activeRequests: (state) =>
		filter(
			(req) => req.status === REQUEST_STATES.active && req.comment !== 'legacy migration',
			state.userInAdmin.id ? state.userInAdmin.requests : state.currentUser.requests
		),

	getSubscription: (state) => (id) => {
		return find({ id: parseInt(id, 10) }, state.currentUser.subscriptions)
	},
	proposalParking: (state, gtrs) => (proposalId) => {
		return gtrs.isLoaded ? find({ id: proposalId }, state.currentUser.proposals)?.parking || {} : {}
	},
	parkingRequested: (state, gtrs) => (addressId) => {
		return includes(addressId, map('addressId', gtrs.activeRequests))
	},
	activeAddresses: (state) => {
		if (state.isAdminEdit) {
			if (state.userInAdmin.requests && state.userInAdmin.addresses) {
				return state.userInAdmin.addresses.filter(
					(address) =>
						!state.userInAdmin.requests.some(
							(req) => req.status === REQUEST_STATES.active && req.addressId === address.id
						)
				)
			} else {
				// Why would you want to show admin addresses when editing a user, @Robin?
				// return state.currentUser.addresses
				return []
			}
		} else if (state.currentUser.requests && state.currentUser.addresses) {
			return state.currentUser.addresses.filter(
				(address) =>
					!state.currentUser.requests.some(
						(req) => req.status === REQUEST_STATES.active && req.addressId === address.id
					)
			)
		} else {
			return state.currentUser.addresses
		}
	},
	childId: (state) => (state.currentUser.parentId ? state.currentUser.id : undefined),

	updateUser: (state) => state.userInAdmin,
}

// Actions
const actions = {
	setCurrentUser({ commit }, payload) {
		commit('setCurrentUser', payload)
	},
	setHasVerificationId({ commit }, payload) {
		commit('setHasVerificationId', payload)
	},
	setTemporaryAccess({ commit }, ta) {
		commit('setTemporaryAccess', ta)
	},
	addSubscription({ commit }, payload) {
		commit('addSubscription', payload)
	},
	async getCurrentUser({ commit, dispatch }) {
		if (getChildToken()) {
			if (!(await dispatch('switchAccount', getChildToken()))) {
				clearChildToken()
				await dispatch('getCurrentUser')
			}
		} else {
			commit('setLoading', true)

			try {
				/* const response = await graphqlClient.query({
					query: gql`
						query CurrentUser {
							currentUser {
								${userProps}
							}
						}
					`,
					fetchPolicy: 'no-cache',
				}) */
				const response = await AuthService.getCurrentUser()
				commit('setCurrentUser', response.data.currentUser)
			} catch (e) {
				logger.error('catching error in current user', e)

				const authRegexp = /unauthorized/i

				if (authRegexp.test(e.message)) {
					localStorage.removeItem('cycloToken')
					router.push({ name: 'login' })
				}
			} finally {
				commit('setLoading', false)
			}
		}
	},

	async toggleNewsletter({ commit }, payload) {
		try {
			await updateUser({}, { newsletter: payload })
			commit('toggleNewsletter', payload)
		} catch (e) {
			logger.error('catching error in newsletter update', e)
		}
	},

	async updateMobib({ commit, dispatch, getters }, cardNumber) {
		try {
			const response = await graphqlClient.mutate({
				mutation: gql`
					mutation UpdateMobib($cardNumber: String!, $childId: Int) {
						updateMobib(cardNumber: $cardNumber, childId: $childId)
					}
				`,
				variables: {
					cardNumber,
					childId: getters.childId,
				},
			})

			commit('updateMobib', response.data.updateMobib)

			return true
		} catch (error) {
			dispatch('alert/error', i18n.t('error.mobibUpdateFailed'), { root: true })
			throw error
		}
	},
	// eslint-disable-next-line max-statements
	async updateProfile({ commit, dispatch, getters }, payload) {
		let user = {}
		const userByAdmin = getters.updateUser
		const isChild = payload.parentId > 0

		if (!isChild) {
			const companyField = payload.company
			const companyAddressField = companyField.address

			const address = pick(AddressProps, companyAddressField)
			const company = Object.assign(pick(CompanyProps, companyField), { address })

			user = Object.assign(pick(UserUpdateProps, payload), { company })
		} else {
			user = Object.assign(pick(UserUpdateProps, payload))
		}

		try {
			let errors = null
			let userOrErrors = null

			userOrErrors = (await updateUser({}, user, getters.childId, userByAdmin.id)).data.updateUser

			errors = userOrErrors.errors

			if (errors) {
				commit('setProfileErrors', errors)
				commit('setInvalid')
				dispatch('alert/error', i18n.t('error.profileUpdateFailed'), { root: true })
			} else {
				if (!isChild) {
					const userUpdates = userOrErrors.user

					user.company.address.id = userUpdates.company.address.id
				}

				commit('updateProfile', user)

				commit('alert/success', i18n.t('flashMessage.profileUpdate'), { root: true })
			}
		} catch (error) {
			commit('setInvalid')
			logger.error('catching error in profile update')
			logger.error(error)
			dispatch('alert/error', i18n.t('error.profileUpdateFailed'), { root: true })

			// throw error
		}
	},

	async updateEmail({ dispatch, commit }, { email, password }) {
		try {
			// const response = await graphqlClient.mutate({
			// 	mutation: gql`
			// 		mutation UpdateEmail($email: String!, $password: String!) {
			// 			updateEmail(email: $email, password: $password)
			// 		}
			// 	`,
			// 	variables: {
			// 		email,
			// 		password,
			// 	},
			// })
			const response = await AuthService.updateUserEmail({ email: email, password: password })
			if (response.data.updateEmail) {
				dispatch('alert/success', i18n.t('flashMessage.emailUpdate'), { root: true })
				commit('updateEmail', email)

				return response.data.updateEmail
			} else {
				dispatch('alert/error', i18n.t('error.updateFailed'), { root: true })

				return false
			}
		} catch (e) {
			dispatch('alert/error', e.message, { root: true })

			return false
		}
	},
	/* eslint-disable-next-line max-statements */
	async updateAddress({ commit, dispatch, getters }, payload) {
		commit('setLoading', true)

		const address = omit(['__typename'], Object.assign({}, payload))
		const userByAdmin = getters.updateUser

		try {
			// const response = userByAdmin.id
			const response = await graphqlClient.mutate({
				mutation: gql`
					mutation UpsertAddress($address: AddressProps!, $childId: Int, $updateUserId: Int) {
						upsertAddress(props: $address, childId: $childId, updateUserId: $updateUserId)
					}
				`,
				variables: {
					address,
					childId: getters.childId,
					updateUserId: userByAdmin.id, // (state.currentUser.parentId ? state.currentUser.id : undefined),
				},
			})

			const responseData = response.data.upsertAddress

			if (responseData) {
				commit('updateAddresses', { address, id: responseData })
				dispatch('alert/success', i18n.t('flashMessage.addressUpdate'), { root: true })
			} else {
				dispatch('alert/error', i18n.t('error.updateFailed'), { root: true })
			}
		} catch (error) {
			dispatch('alert/error', i18n.t('error.updateFailed'), { root: true })
			throw error
		} finally {
			commit('setLoading', false)
		}
	},

	async rejectProposal({ commit, dispatch, getters }, { proposalId, keep, rejectReason }) {
		commit('setLoading', true)

		const response = await graphqlClient.mutate({
			mutation: gql`
				mutation rejectProposal($id: Int!, $keep: Boolean!, $rejectReason: String, $childId: Int) {
					rejectProposal(id: $id, keep: $keep, rejectReason: $rejectReason, childId: $childId)
				}
			`,
			variables: {
				id: proposalId,
				keep,
				rejectReason,
				childId: getters.childId,
			},
		})

		if (response.data.rejectProposal) {
			dispatch('alert/success', i18n.t('flashMessage.proposalRejected'), { root: true })
			commit('rejectProposal', { proposalId, keep })
		} else {
			dispatch('alert/error', `Proposal reject failed`, { root: true })
		}

		commit('setLoading', false)
	},

	async removeAddress({ commit, dispatch, getters }, id) {
		commit('setLoading', true)

		const userByAdmin = getters.updateUser

		try {
			const response = await graphqlClient.mutate({
				mutation: gql`
					mutation DeleteAddress($id: Int!, $childId: Int, $updateUserId: Int) {
						deleteAddress(id: $id, childId: $childId, updateUserId: $updateUserId)
					}
				`,
				variables: {
					id,
					childId: getters.childId,
					updateUserId: userByAdmin.id,
				},
			})

			if (response.data.deleteAddress) {
				commit('removeAddress', id)
			} else {
				dispatch('alert/error', response.errors[0].message, { root: true })
			}

			return true
		} catch (error) {
			dispatch('alert/error', error.message, { root: true })
			throw error
		} finally {
			commit('setLoading', false)
		}
	},
	/* eslint-disable-next-line max-statements */
	async createParkingRequest({ commit, dispatch, getters }, { addressId, isCargo }) {
		commit('setLoading', true)

		const userByAdmin = getters.updateUser

		try {
			const response = await graphqlClient.mutate({
				mutation: gql`
					mutation createParkingRequest(
						$addressId: Int!
						$isCargo: Boolean!
						$childId: Int
						$updateUserId: Int
					) {
						createParkingRequest(
							addressId: $addressId
							isCargo: $isCargo
							childId: $childId
							updateUserId: $updateUserId
						) {
							id
							status
							comment
							type
							requestedAt
							createdAt
							addressId
							isCargo
							address {
								id
								addressType
								streetName
								houseNumber
							}
							parkingsInRange {
								code
							}
						}
					}
				`,
				variables: {
					addressId,
					isCargo,
					childId: getters.childId,
					updateUserId: userByAdmin.id,
				},
			})

			if (response.data.createParkingRequest) {
				commit('createParkingRequest', response.data.createParkingRequest)
				dispatch('alert/success', i18n.t('flashMessage.createParkingRequest'), { root: true })
			} else {
				dispatch('alert/error', i18n.t('error.parkingRequestFailed'), { root: true })
			}
		} catch (error) {
			dispatch('alert/error', i18n.t('error.parkingRequestFailed'), { root: true })
			throw error
		} finally {
			commit('setLoading', false)
			dispatch('parkings/deleteParkingRequestId', '', { root: true })
		}
	},

	async addNewPaymentMethod({ commit, dispatch, getters }, token) {
		commit('setLoading', true)
		try {
			const response = await graphqlClient.mutate({
				mutation: gql`
					mutation addNewPaymentMethod($token: String) {
						addNewPaymentMethod(token: $token) {
							url
						}
					}
				`,
				variables: {
					token,
				},
			})

			if (response.data.addNewPaymentMethod) {
				return response.data.addNewPaymentMethod
			}
		} catch (error) {
			commit('setLoading', false)
			dispatch('alert/error', error.message, { root: true })
			throw error
		}
	},
	async renewBancontact({ commit, dispatch, getters }, obj) {
		commit('setLoading', true)
		try {
			const response = await graphqlClient.mutate({
				mutation: gql`
					mutation renewBancontact($subscriptionId: Int, $type: String) {
						renewBancontact(subscriptionId: $subscriptionId, type: $type) {
							url
						}
					}
				`,
				variables: {
					subscriptionId: obj.subId,
					type: obj.type,
				},
			})

			if (response.data.renewBancontact) {
				return response.data.renewBancontact
			}
		} catch (error) {
			commit('setLoading', false)
			dispatch('alert/error', error.message, { root: true })
			throw error
		}
	},
	async sendPaymentLink({ commit, dispatch, getters }, data) {
		commit('setLoading', true)
		try {
			const response = await graphqlClient.mutate({
				mutation: gql`
					mutation sendPaymentLink($subscriptionId: Int, $invoiceItemId: Int) {
						sendPaymentLink(subscriptionId: $subscriptionId, invoiceItemId: $invoiceItemId) {
							url
						}
					}
				`,
				variables: {
					subscriptionId: data.subId,
					invoiceItemId: data.invoiceItem,
				},
			})

			if (response.data.sendPaymentLink) {
				return response.data.sendPaymentLink
			}
		} catch (error) {
			commit('setLoading', false)
			dispatch('alert/error', error.message, { root: true })
			throw error
		}
	},
	async deletePaymentMethod({ commit, dispatch, getters }, source) {
		commit('setLoading', true)
		const { customerId, mandateId } = source
		try {
			const response = await graphqlClient.mutate({
				mutation: gql`
					mutation DeletePaymentMethod($customerId: String!, $mandateId: String!) {
						deletePaymentMethod(customerId: $customerId, mandateId: $mandateId) {
							createdAt
							customerId
							default
							details {
								cardExpiryDate
								cardFingerprint
								cardHolder
								cardLabel
								cardNumber
							}
							id
							mandateReference
							method
							mode
							signatureDate
							status
						}
					}
				`,
				variables: {
					customerId,
					mandateId,
				},
			})

			if (response.data.deletePaymentMethod) {
				return response.data.deletePaymentMethod
			}
		} catch (error) {
			commit('setLoading', false)
			dispatch('alert/error', error.message, { root: true })
			throw error
		}
	},
	async defaultPaymentMethod({ commit, dispatch, getters }, source) {
		commit('setLoading', true)
		const { customerId, mandateId } = source
		try {
			const response = await graphqlClient.mutate({
				mutation: gql`
					mutation DefaultPaymentMethod($customerId: String!, $mandateId: String!) {
						defaultPaymentMethod(customerId: $customerId, mandateId: $mandateId) {
							createdAt
							customerId
							default
							details {
								cardExpiryDate
								cardFingerprint
								cardHolder
								cardLabel
								cardNumber
							}
							id
							mandateReference
							method
							mode
							signatureDate
							status
						}
					}
				`,
				variables: {
					customerId,
					mandateId,
				},
			})

			if (response.data.defaultPaymentMethod) {
				return response.data.defaultPaymentMethod
			}
		} catch (error) {
			commit('setLoading', false)
			dispatch('alert/error', error.message, { root: true })
			throw error
		}
	},
	async cancelParkingRequest({ commit, dispatch, getters }, requestId) {
		commit('setLoading', true)

		const userByAdmin = getters.updateUser

		const response = await graphqlClient.mutate({
			mutation: gql`
				mutation cancelParkingRequest($id: Int!, $childId: Int, $updateUserId: Int) {
					cancelParkingRequest(id: $id, childId: $childId, updateUserId: $updateUserId)
				}
			`,
			variables: {
				id: requestId,
				childId: getters.childId,
				updateUserId: userByAdmin.id,
			},
		})

		if (response.data.cancelParkingRequest) {
			dispatch('alert/success', `Request cancelled`, { root: true })
			commit('cancelParkingRequest', requestId)
		} else {
			dispatch('alert/error', `Request cancel failed`, { root: true })
		}

		commit('setLoading', false)
	},
	/* eslint-disable-next-line max-statements */
	async addChildAccount({ commit, dispatch }, payload) {
		// const id = uniqueId('newId_')

		commit('setLoading', true)

		try {
			const response = await graphqlClient.mutate({
				mutation: gql`
					mutation createChildUser($child: ChildUserProps!) {
						createChildUser(props: $child)
					}
				`,
				variables: {
					child: payload,
				},
			})

			if (response.data.createChildUser) {
				commit('addChildAccount', { ...payload, id: response.data.createChildUser })

				dispatch('switchAccount', response.data.createChildUser)
				dispatch('alert/success', i18n.t('flashMessage.childAccountCreated'), { root: true })
			}
		} catch (error) {
			dispatch('alert/error', i18n.t('error.updateFailed'), { root: true })
			throw error
		}

		// setLoading('false') done in the switchAccount action
	},

	/* eslint-disable-next-line */
	async switchAccount({ commit, state }, payload) {
		commit('setLoading', true)

		try {
			const response = await graphqlClient.query({
				query: gql`
					query childUser($userId: Int!) {
						childUser(userId: $userId) {
							${childUserProps}
						}
					}
				`,
				variables: {
					userId: payload,
				},
			})

			localStorage.setItem('cycloChild', payload)

			commit('setParentUser', state.currentUser)

			commit('setCurrentUser', response.data.childUser)
		} catch (e) {
			logger.error('catching error in current user', e)

			return false
		} finally {
			commit('setLoading', false)
		}

		return true
	},
	async backToParentAccount({ commit, dispatch, state }) {
		clearChildToken()

		if (!state.parentUser.id) {
			await dispatch('getCurrentUser')
		} else {
			commit('setCurrentUser', state.parentUser)
		}

		commit('clearParentUser')
	},
	async deleteFamilyAccount({ commit, dispatch }, childId) {
		try {
			const response = await graphqlClient.mutate({
				mutation: gql`
					mutation deleteChildUser($childId: Int!) {
						deleteChildUser(childId: $childId)
					}
				`,
				variables: {
					childId,
				},
			})

			if (response.data.deleteChildUser) {
				commit('deleteFamilyAccount', childId)
				dispatch('alert/success', i18n.t('flashMessage.childAccountDeleted'), { root: true })
				dispatch('backToParentAccount')
			}
		} catch (error) {
			dispatch('alert/error', i18n.t('error.familyAccount.deleteFailed'), { root: true })
			throw error
		} finally {
			commit('setLoading', false)
		}
	},

	async updatePaymentMethod({ commit }) {
		commit('setLoading', true)

		const redirectUrl = config.profileRedirectUrl

		// logger.debug('redirectUrl: ', redirectUrl)

		try {
			const response = await graphqlClient.query({
				query: gql`
					query UpdatePaymentMethod($redirectUrl: String) {
						updatePaymentMethod(redirectUrl: $redirectUrl) {
							url
						}
					}
				`,
				variables: { redirectUrl },
			})

			return response.data.updatePaymentMethod.url
		} catch (e) {
			// logger.error('catching error in update payment method', e)

			commit('setLoading', false)

			return false
		}
	},
	async getPaymentMethod({ commit }, Id) {
		commit('setLoading', true)

		try {
			const response = await graphqlClient.query({
				query: gql`
					query GetPaymentMethods($Id: Int) {
						getPaymentMethods(Id: $Id) {
							createdAt
							customerId
							default
							details {
								cardExpiryDate
								cardFingerprint
								cardHolder
								cardLabel
								cardNumber
							}
							id
							mandateReference
							method
							mode
							signatureDate
							status
						}
					}
				`,
				variables: { Id },
				fetchPolicy: 'no-cache',
			})
			if (response.data) {
				commit('setLoading', false)
				return response.data.getPaymentMethods
			}
		} catch (e) {
			// logger.error('catching error in update payment method', e)

			commit('setLoading', false)

			return false
		}
	},
	editUserByAdmin({ commit }, user) {
		commit('setuserInAdmin', user)
		router.push({ name: 'profile' })
	},
	exitAdminEdit({ commit }) {
		commit('exitAdminEdit')
		router.push({ name: 'admin' })
	},
	resetState({ commit }) {
		commit('resetState')
	},
}

// Mutations
const mutations = {
	setCurrentUser(state, user) {
		const companyAddress = {
			busNumber: null,
			city: null,
			houseNumber: null,
			id: null,
			postalCode: null,
			streetName: null,
		}
		if (!user.company.address) {
			user.company.address = companyAddress
		}
		// Todo hack to force newsletter on false instead of null
		// state.currentUser = Object.assign({}, state.currentUser, user, { newsletter: user.newsletter || false })

		state.currentUser = Object.assign({}, user, { newsletter: user.newsletter || false })
	},
	setHasVerificationId(state, hasVerificationId) {
		state.currentUser.hasVerificationId = hasVerificationId
	},
	addSubscription(state, sub) {
		const idx = findIndex((s) => s.id === sub.id, state.currentUser.subscriptions)

		if (idx >= 0) {
			Vue.set(state.currentUser.subscriptions, idx, sub)
		} else {
			state.currentUser.subscriptions = Object.assign(
				[],
				(state.currentUser.subscriptions || []).concat(sub)
			)
		}
	},
	changeLanguage(state, language) {
		state.currentUser.language = language
	},
	toggleNewsletter(state, payload) {
		state.currentUser.newsletter = payload // !state.currentUser.newsletter
	},
	updateMobib(state, payload) {
		state.currentUser.mobib = payload
	},
	updateEmail(state, payload) {
		state.currentUser.email = payload
	},
	setInvalid(state) {
		state.valid = false
	},
	setProfileErrors(_, errors) {
		state.formErrors = Object.assign({}, errors)
		logger.debug("We are in: 'profileErrors'", errors)
	},
	removeProfileError(_, error) {
		state.formErrors[error] = false
	},
	removeProfileErrors() {
		state.formErrors = {}
	},
	updateProfile(state, payload) {
		state.valid = true

		const updateUser = state.userInAdmin.id ? 'userInAdmin' : 'currentUser'

		state[updateUser] = Object.assign({}, state[updateUser], payload) // !state.currentUser.newsletter
	},

	activateAccount(state, phoneNumber) {
		state.currentUser.status = 1
		state.currentUser.phoneNumber = phoneNumber
	},
	activatePhoneNumber(state, phoneNumber) {
		state.currentUser.phoneNumber = phoneNumber
		state.currentUser.phoneVerified = true
	},
	setLoading(state, status) {
		state.loading = status
	},
	updateAddresses(state, { address, id }) {
		// const index = state.currentUser.addresses.findIndex(addr => addr.id === id)
		const userForAddress = state.userInAdmin.id ? 'userInAdmin' : 'currentUser'
		const index = findIndex((addr) => addr.id === id, state[userForAddress].addresses)

		if (index >= 0) {
			// state[userForAddress].addresses[index] = address
			Vue.set(state[userForAddress].addresses, index, address)
		} else {
			if (!state[userForAddress].addresses) {
				state[userForAddress].addresses = []
			}

			state[userForAddress].addresses.push(Object.assign({}, address, { id }))
		}
	},
	removeAddress(state, id) {
		const userForAddress = state.userInAdmin.id ? 'userInAdmin' : 'currentUser'
		const index = findIndex((addr) => addr.id === id, state[userForAddress].addresses)
		const requestIndex = findIndex((req) => req.addressId === id, state[userForAddress].requests)

		state[userForAddress].addresses.splice(index, 1)

		if (requestIndex >= 0) {
			state[userForAddress].requests.splice(requestIndex, 1)
		}
	},

	rejectProposal(state, { proposalId, keep }) {
		const prop = find({ id: proposalId }, state.currentUser.proposals)

		if (!keep) {
			const request = find({ id: prop.requestId }, state.currentUser.requests)

			Vue.set(request, 'status', REQUEST_STATES.cancelled)
		}

		Vue.set(prop, 'status', PROPOSAL_STATES.rejected)
	},

	acceptProposal(state, id) {
		const prop = find({ id }, state.currentUser.proposals)
		const req = find({ id: prop.requestId }, state.currentUser.requests)
		const sub = find({ proposalId: id }, state.currentUser.subscriptions)

		if (req) {
			Vue.set(req, 'status', REQUEST_STATES.completed)
		}
		if (sub.status != 1) {
			Vue.set(prop, 'status', PROPOSAL_STATES.accepted)
		}
	},
	createParkingRequest(state, request) {
		const updateUserLocation = state.isAdminEdit ? 'userInAdmin' : 'currentUser'

		if (state[updateUserLocation].requests) {
			state[updateUserLocation].requests.push(request)
		} else {
			state[updateUserLocation].requests = [request]
		}
	},
	cancelParkingRequest(state, id) {
		const updateUserLocation = state.isAdminEdit ? 'userInAdmin' : 'currentUser'
		const idx = findIndex((req) => req.id === id, state[updateUserLocation].requests)

		Vue.delete(state[updateUserLocation].requests, idx)
	},
	addChildAccount(state, newAccount) {
		if (state.currentUser.children) {
			state.currentUser.children.push(Object.assign({}, initialState().currentUser, newAccount))
		} else {
			state.currentUser.children = [newAccount]
		}
	},

	setChildStatus(state, status) {
		state.isChild = status
	},
	setParentUser(state, user) {
		state.parentUser = Object.assign({}, user)
		state.isChild = true
	},
	clearParentUser(state) {
		state.parentUser = {}
		state.isChild = false
	},
	deleteFamilyAccount(state, id) {
		const index = findIndex((child) => child.id === id, state.parentUser.children)

		Vue.delete(state.parentUser.children, index)
	},
	resetState(state) {
		Object.assign(state, initialState())
	},
	setTemporaryAccess(state, ta) {
		state.activeTemporaryAccess = ta
	},
	setuserInAdmin(state, user) {
		state.userInAdmin = Object.assign({}, user)
		state.isAdminEdit = true
	},
	exitAdminEdit(state) {
		state.userInAdmin = {}
		state.isAdminEdit = false
	},

	setActiveAddressId(state, newValue) {
		state.activeAddressId = newValue
	},
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
	modules: {
		myParkings,
	},
}
