import { config } from '../config'
import { logger } from '../logger'

import axios from 'axios'

const APIClient = axios.create({
	baseURL: config.apiUrl,
});


APIClient.interceptors.request.use(config => {
	const token = localStorage.getItem('cycloToken')
	if (token) {
		config.headers.Authorization = token ? `Bearer ${token}` : null
	}
	return config;
});

APIClient.interceptors.response.use(
	response => response.data,
	error => {
		const status = error.response ? error.response.status : null;
		const message = error.response ? (error.response.data.message?.details && error.response.data.message?.details[0].message) || error.response.data.message : error.message;
		const path = error.response ? error.response.data.path : '';
		logger.error(`[API error]: Message: ${message}, Path: ${path}`)

		if (status === 401) {
			// Handle unauthorized access
		} else if (status === 404) {
			// Handle not found errors
		} else {
			// Handle other errors
		}

		return Promise.reject({ message, details: error.response.data.message?.details , ...error});
	}
);

export default APIClient;